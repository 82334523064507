export default {
  tempNames: {
    frontName: [
      '불안한',
      '무리한',
      '다정한',
      '선명한',
      '뚱뚱한',
      '심각한',
      '억울한',
      '침착한',
      '캄캄한',
      '건전한',
      '취한',
      '단단한',
      '대단한',
      '편리한',
      '강렬한',
      '슬퍼한',
      '가득한',
      '속상한',
      '신기한',
      '건강한',
      '성실한',
      '곤란한',
      '실망한',
      '완벽한',
      '솔직한',
      '야한',
      '편한',
      '초조한',
      '무서운',
      '무거운',
      '무례한',
      '무시한',
      '퇴근한',
      '조용한',
      '답답한',
      '당황한',
      '피곤한',
      '부러운',
      '졸린',
      '똑똑한',
      '섬세한',
      '무뚝뚝한',
      '무책임한',
      '무관심한',
      '무표정한',
      '무심한',
      '건강한',
      '우아한',
      '민감한',
      '예민한',
      '즐거운',
      '명확한',
      '괴로운',
      '유명한',
      '우울한',
      '순한',
      '어색한',
      '지루한',
      '특별한',
      '신중한',
      '딱딱한',
      '정직한',
      '섹시한',
    ],
    backName: [
      '늑대',
      '냄비',
      '쓰레기',
      '언론',
      '부엌',
      '입구',
      '당근',
      '유물',
      '이모',
      '파티',
      '부인',
      '등록금',
      '퇴직금',
      '캠퍼스',
      '조깅',
      '잔디',
      '알코올',
      '신부',
      '바퀴',
      '서적',
      '연구원',
      '학부모',
      '변호사',
      '농부',
      '거울',
      '세제',
      '고추',
      '빵',
      '리모컨',
      '컴퓨터',
      '키보드',
      '마우스',
      '휴대폰',
      '커피',
      '콜라',
      '물',
      '빗',
      '칫솔',
      '치약',
      '헤어드라이기',
      '헤어스프레이',
      '왁스',
      '스승',
      '등대',
      '포도',
      '바나나',
      '사과',
      '배',
      '수박',
      '참외',
      '토마토',
      '매밀',
      '식빵',
      '총각',
      '태풍',
      '형부',
      '무덤',
      '소나무',
      '포도주',
      '술',
      '맥주',
      '소주',
      '와인',
      '술병',
      '술잔',
      '라이터',
      '인형',
      '구두',
      '신발',
      '양말',
      '바지',
      '치마',
      '원피스',
      '티셔츠',
      '셔츠',
      '반바지',
      '모자',
      '안경',
      '소녀',
      '소년',
      '아가씨',
      '아저씨',
      '아주머니',
      '엉덩이',
      '야채',
      '파도',
      '화가',
      '페인트',
      '물감',
      '유학생',
      '강아지',
      '고양이',
      '토끼',
      '코뿔소',
      '돼지',
      '닭',
      '오리',
      '거위',
      '악어',
      '고래',
      '상어',
      '물고기',
      '거북이',
      '개구리',
      '뱀',
      '독수리',
      '호랑이',
      '사자',
      '여우',
      '곰',
      '쥐',
      '고슴도치',
      '얼룩말',
      '하마',
      '햄스터',
      '코끼리',
      '모기',
      '신랑',
      '침대',
      '지도',
      '크림',
      '햄버거',
      '칼국수',
      '냉면',
      '향수',
      '치즈',
    ],
  },
}
